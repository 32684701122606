import { NS_BUSINESS, NS_HOME, NS_PRICING } from 'constants/i18n';
import { createStaticRoute } from 'modules/prerender/createRoute';
import { getRecentArticles } from 'modules/prerender/services/posts';
import type { Review } from 'types/content';

const { getStaticPaths, getStaticProps } = createStaticRoute({
  path: '/',
  translateNamespace: [NS_HOME, NS_PRICING, NS_BUSINESS],
  getRouteData: getRecentArticles,
  getData: ({ reviews }: { reviews: Review[] }) => ({
    reviews,
    identifierPopupEnabled: true,
  }),
});

export { HomePage as default } from 'components/landings/HomePage';
export { getStaticPaths, getStaticProps };
